import React from 'react';
import './details.css'
import lang from '../../Atoms/lang'
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil'
import { useParams } from 'react-router-dom';


export default function BlogDetails() {
    const params = useParams()

    const langs = useRecoilValue(lang)


    const [details, setDetails] = useState({})
    useEffect(() => {
        fetch(`https://smilehousedentalcenter.com/api/blog/${params.slug}`)
        .then((res) => res.json())
        .then((data)=> setDetails(data.data))
    },[params,langs])
return (
    
<div className="blog-area pt-3" lang={`${langs}`}>
    <div className="container">
        <div className="sec-title centered">
            <h2>
            {langs ? "تفاصيل الخبر" : "Blog Details"}  
            </h2>
            <div className="separator"></div>
        </div>
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="blog_detaisl_area">
                    <div className="blog_full_content">
                    <img src={details.photo_url}
                                    alt={details.name} />
                        <h4> {langs ? details.title_ar : details.title}
                        </h4>


                    </div>

                    <p>
                    {langs ? details.details_ar : details.details}
                    </p>
                </div>
            </div>
           
            </div>
            </div>
            </div>

  
)
}