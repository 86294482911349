import React, { useEffect, useState }from 'react';
import lang from '../../Atoms/lang'
import { useRecoilValue } from 'recoil'
import './blogs.css';
import { Link } from 'react-router-dom';    

const Blogs = () => {

 
    const langs = useRecoilValue(lang)

    const [blogName , setBlogName] = useState([])
    useEffect(() => {
      fetch("https://smilehousedentalcenter.com/api/blogs")
        .then((res) => res.json())
        .then((data) => setBlogName(data.data))
      }, [])
     

return (

<>
    <section className='blogs_area' lang={`${langs}`}>
        <div className="container">
            <div className="sec-title centered">
                <Link to="/blogs">
                    <h2>
                {langs ? "اخر الأخبار" : "Blogs"}   

                    </h2>
                </Link>
                {/*
                <!--     <h2>اخر الأخبار</h2>--> */}
                <div className="separator"></div>
            </div>
                <div className="row">
                {blogName.map((bloger, index) => {
                    if (index <= 4) {  
                        return (
                <div className="col-lg-4" key={index}>
                    <div className="blog-card text-center">
                        <Link to={`../blog-detalis/${bloger.slug}`}>
                        <img src={bloger.photo_url}
                            alt="ايه فؤاد تنظيف الاسنان" width={"100%"} height={"100%"}  />
                        </Link>
                        <div className="b-card-text">
                            <h3>
                                <Link to={`../blog-detalis/${bloger.slug}`}>
                                {langs ? bloger.title_ar : bloger.title}
                                </Link>
                            </h3>
                            <p> 
                            {langs ? bloger.mini_details_ar : bloger.mini_details}
                            </p>
                            <div className="view-more">
                                <Link to={`../blog-detalis/${bloger.slug}`}>
                                
                            {langs ? "أقرا المزيد" : "Read More"}   

                                <i className="bx bx-right-arrow-alt"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    </div>
                    )}
                    return (
                        <div className="col-lg-4"  key={index}>
                            <div className="blog-card text-center">
                                <Link to={`../blog-detalis/${bloger.slug}`}>
                                <img src={bloger.photo_url}
                                    alt="ايه فؤاد تنظيف الاسنان" width={"100%"} height={"100%"}  />
                                </Link>
                                <div className="b-card-text">
                                    <h3>
                                        <Link to={`../blog-detalis/${bloger.slug}`}>
                                        {langs ? bloger.title_ar : bloger.title}
                                        </Link>
                                    </h3>
                                    <p> 
                                    {langs ? bloger.mini_details_ar : bloger.mini_details}
                                    </p>
                                    <div className="view-more">
                                        <Link to={`../blog-detalis/${bloger.slug}`}>
                                        
                                    {langs ? "أقرا المزيد" : "Read More"}   
        
                                        <i className="bx bx-right-arrow-alt"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            </div>
                            )
                
                 
        })}
                  
                </div>
        </div>
    </section>

</>
)
}
export default Blogs;