import React, { useRef } from 'react'
import './form.css'
// import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import lang from '../../Atoms/lang'
import emailjs from '@emailjs/browser';
import { useState } from 'react'


const Result =() =>{
    const langs =useRecoilValue(lang)
    return (
        <div className="alert alert-primary fw-bolder" role="alert">
        {langs ? "تم ارسال الرساله, شكرا لك !" : "Message sent, thank you!"}
  </div>
    )
}
export default function Form() {

    const langs =useRecoilValue(lang)

    const form = useRef();
    const [result,showResult] = useState(false)
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_cilyfye', 'template_8ik2xwv', form.current, '4I64qfeN03bFuoTV_')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        }
        );
        e.target.reset();
        showResult(true);
      };
    return (
    
        

<section className="ftco-section" lang={`${langs}`}>
    <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
                <div className="wrapper">
                    <div className="row no-gutters">
                        <div className="col-md-7 d-flex align-items-stretch">
                            <div className="contact-wrap w-100 p-md-5 p-4">
                                        <h3 className="mb-4 fw-500">
                                            {langs ? "تواصل معنا" : "Contact Us"}    
                                        </h3>
                                <div id="form-message-warning" className="mb-4"></div>
                                <div id="form-message-success" className="mb-4">
                                    تم ارسال الرساله, شكرا لك !
                                </div>
                                <form id="contactForm"  ref={form} onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="name" id="name"
                                                    placeholder={langs ? "الأسم" : "Name"} required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="tel" minLength="11" maxLength="20" className="form-control" name="phone" id="phone"
                                                    placeholder={langs ? "رقم الهاتف" : "phone"} required/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" id="email"
                                                    placeholder={langs ? "البريد الألكتروني" : "E-mail"} />
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" id="message" cols="30"
                                                    rows="7" placeholder={langs ?"الرساله" : "Message"}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="submit" value={langs ? "ارسال الرساله" : "Send Message"}
                                                    className="btn theme-btn-one" />
                                                <div className="submitting"></div>
                                            </div>
                                        </div>
                                    </div>
                                    {result ? <Result/> : null}

                                </form>
                            </div>
                        </div>
                        <div className="col-md-5 d-flex align-items-stretch">
                            <div className="info-wrap bg-primary w-100 p-lg-5 p-4" lang={`${langs}`}>
                                <div className="dbox w-100 d-flex align-items-start">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="fa fa-map-marker"></span>
                                    </div>
                                    <div className="text pl-3">
                                                <p><span>{langs ? "العنوان:" : "Address : "}</span> 
                                                <a href="https://goo.gl/maps/2akvCmzMAAn9uYb68">
                              {langs ? "6 ش جلال متفرع من ش اسكندر الاكبر خلف قصر الرئاسة - الكوربة - مصر الجديدة..السبت الي الخميس من ١٠ صباحا حتي ٦ مساء" : "6 M. Galal St. off Eskander el Akbar St. behind Presidential Palace – Korba – Heliopolis.Saturday to Thursday from 10 am to 6 pm"}
                                </a>
                                </p>                           
                                         </div>
                                </div>
                                <div className="dbox w-100 d-flex align-items-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="fa fa-phone"></span>
                                    </div>
                                    <div className="text pl-3">
                                                <p>
                                                    <a href="tel:+201069000434">01069000434</a>
                                                    <span>{langs ? "رقم الهاتف :" : "Phone Number :"}</span>
                                                </p>
                                    </div>
                                </div>
                                <div className="dbox w-100 d-flex align-items-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="fa fa-paper-plane"></span>
                                    </div>
                                    <div className="text pl-3">
                                                <p><span>{langs ? "البريد الألكتروني :" : "E-Mail : "}</span>  <a href="mailto:info@smilehousedentalcenter.com">info@smilehousedentalcenter.com</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="dbox w-100 d-flex align-items-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="fa fa-globe"></span>
                                    </div>
                                    <div className="text pl-3">
                                                <p><span>{langs ? "الموقع الألكتروني :" : "Website :"}</span>  <a href="https://www.smilehousedentalcenter.com/">smilehousedentalcenter.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)
}