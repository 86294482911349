import React, { useEffect, useState ,    useRef} from 'react';
import './Service-details.css';
import MyBackgroundImage from './logo.png';
import { useParams } from 'react-router-dom';   
import { useRecoilValue } from 'recoil';
import lang from '../../Atoms/lang';
import emailjs from '@emailjs/browser';
import { map } from 'jquery';



const Result =() =>{
    const langs =useRecoilValue(lang)

    return (
        <div className="alert alert-primary fw-bolder" role="alert">
            {langs ? "تم ارسال الرساله, شكرا لك !" : "Message sent, thank you!"}
      </div>
    )
}
export default function ServiceDetails() {

    const params = useParams()
    const langs = useRecoilValue(lang)

    const [serviceDetails, setDetails] = useState({})

    let questions_ar = (serviceDetails.title_ar)?.split(",")
    let answers_ar = (serviceDetails.desc_ar)?.split(",")
    let questions_en = (serviceDetails.title)?.split(",")
    let answers_en = (serviceDetails.desc)?.split(",")

    
    useEffect(() => {
        fetch(`https://smilehousedentalcenter.com/api/service/${params.slug}`)
        .then((res) => res.json())
        .then((data)=> setDetails(data.data))
    },[params,langs])
    
    const form = useRef();
    const [result,showResult] = useState(false)
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_cilyfye', 'template_8ik2xwv', form.current, '4I64qfeN03bFuoTV_')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        }
        );
        e.target.reset();
        showResult(true);
      };

return (
         <section className="service_details" lang={`${langs}`}>
                <div className="container">
                    <div className="image-layer" style={{   backgroundImage: `url(${MyBackgroundImage})`,}}>

                    </div>
                    <div className="row">
                       
                    <div className="col-lg-8 col-md-12">
                        <div className="row">
                        <div className="col-md-12">
                            <div className="service_details_wraper">
                                <img src={serviceDetails.photo_url}
                                    alt={serviceDetails.name} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="service_details_wraper">
                                <div className="text-p">
                                    <p> {langs ? serviceDetails.name_ar : serviceDetails.name}</p>
                                </div>
                            
                                <p>{langs ? serviceDetails.details_ar : serviceDetails.details}</p>
                          
                                 { langs ?
                                    questions_ar?.map((so2al , index) =>{

                                        let ans
                                        if(answers_ar[index].includes(":")) {
                                             ans = answers_ar[index].split(":")[1].split(".")
                                        }

                                        return (
                                            <div className="ask" key={index}> 
                                                <div className="text-p"> 
                                                    <p>{so2al}</p>
                                                </div>
                                                <p>{answers_ar[index].split(":")[0]}
                                                <ul>
                                                    {ans?.map((s, index)=>{
                                                        return(
                                                            <li key={index}>{s}</li>
                                                        )
                                                    })}
                                                </ul>
                                                </p>
                                            </div>
                                        )
                                    }) :
                                    questions_en?.map((so2al , index) =>{

                                        let ans
                                        if(answers_en[index].includes(":")) {
                                             ans = answers_en[index].split(":")[1].split(".")
                                        }

                                        return (
                                            <div className="ask" key={index}> 
                                                <div className="text-p"> 
                                                    <p>{so2al}</p>
                                                </div>
                                                <p>{answers_en[index].split(":")[0]}
                                                <ul>
                                                    {ans?.map((s)=>{
                                                        return(
                                                            <li>{s}</li>
                                                        )
                                                    })}
                                                </ul>
                                                </p>
                                            </div>
                                        )
                                    })

                                 }
                                    

                            
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 position-sticky sticky-top h-100">
                           <div className="contact-wrap w-100 p-md-5 p-4 pt-md-3">
                                <h3 className="mb-4 fw-500">
                                    {langs ? "  احجز موعد" : "Book Now"}
                                </h3>
                                <form id="contactForm pb-0"  ref={form} onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="name" id="name"
                                                    placeholder={langs ? "الأسم" : "Name"} required />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" id="email"
                                                    placeholder={langs ? "البريد الألكتروني" : "E-mail"} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <input type="tel" minLength="11" maxLength="20" className="form-control" name="phone" id="phone"
                                                    placeholder={langs ? "رقم الهاتف" : "phone"} required/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" id="message" cols="30"
                                                    rows="7" placeholder={langs ?"الرساله" : "Message"}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="submit" value={langs ? "ارسال الرساله" : "Send Message"}
                                                    className="btn theme-btn-one" />
                                                <div className="submitting"></div>
                                            </div>
                                        </div>
                                    </div>
                                    {result ? <Result/> : null}

                                </form>
                            </div>
                        </div>
                        {/* <div className="col-lg-12">
                            

                        <iframe src="" frameborder="0">
                            {Details.youtube}
                        </iframe>
                        </div> */}


                    </div>

                </div>
            </section>
    )
    }
    