import React from 'react';
import { useRecoilValue } from 'recoil';
import lang from '../Atoms/lang';

const CallsButtons = () => {
    const langs = useRecoilValue(lang)
    return (
        <div className="CTA-btns mt-3 mb-3" lang={`${langs}`}>
        <div className="container">
            <div className="row text-center">
                <div className="col-lg-6">
                     <a href="tel:+201069000434" className="btn theme-btn-one bg-blue"> { langs ? "اتصل بنا" : "Call us"}</a>
                </div>
                  <div className="col-lg-6">
                     <a href="https://wa.me/201069000434" className="btn theme-btn-one bg-green"> { langs ? "تواصل عبر الواتساب" : "Contact WhatsApp"}</a>
                </div>
            </div>
        </div>
    </div>
    );
}

export default CallsButtons;
