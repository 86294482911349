import React from 'react';
import './videos.css'
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import lang from '../../Atoms/lang';
// import { useEffect } from 'react';
export default function Videos() {

    const langs = useRecoilValue(lang)

  //   const [videos , setVideo] = useState([])

  // useEffect(() => {
  //     fetch("https://smilehousedentalcenter.com/api/videos")
  //           .then((res) => res.json())
  //           .then((data) => setVideo(data.data))
  //   },[])

  
return (
<section className="vedios" lang={`${langs}`}>
  <div className="container">
    <div className="sec-title centered">
      <Link to="/videos">
          <h2>{ langs ? "معرض الفيديوهات" : "Videos" }</h2>
      </Link>
      <div className="separator"></div>
    </div>
      <div className="row">
        

      <div className="col-lg-4">
        <div id="ytplayer"><iframe width="560" height="315" src="https://www.youtube.com/embed/mA7HZCyF8Ms"
            title="YouTube video player" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""></iframe> </div>

      </div>

      <div className="col-lg-4">
        <div id="ytplayer"><iframe width="560" height="315" src="https://www.youtube.com/embed/sdDRsbPq3e8"
            title="YouTube video player" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""></iframe> </div>

      </div>

      <div className="col-lg-4">
        <div id="ytplayer"><iframe width="560" height="315" src="https://www.youtube.com/embed/ZaTBnDmhi1I"
            title="YouTube video player" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""></iframe> </div>

      </div>


    </div>
  </div>
</section>
)
}