import React, { useEffect, useState }from 'react';
import { Link } from 'react-router-dom';
import './servicesCard.css'
import { useRecoilValue } from 'recoil';
import lang from '../../Atoms/lang';


const ServiceCard = () => {
    
    const langs = useRecoilValue(lang)


    const [serviceName , setServiceName] = useState([])
    useEffect(() => {
      fetch("https://smilehousedentalcenter.com/api/services")
        .then((res) => res.json())
        .then((data) => setServiceName(data.data))
      }, [])
      
  
return (
    <div className="department-section" lang={`${langs}`}>
        <div className="container">
            <div className="sec-title centered">
                <h2>{ langs ? "خدمات سمايل هاوس" : "Services" }</h2>
                <div className="separator"></div>
            </div>
            <div className="row">

              

                {serviceName.map((service, index) => {
                      return (
                        <div className="col-lg-4 col-md-6" key={index}>
                        <div className="department-box">
                            <div className="inner-box">
                                <div className="image">
                                    <Link to={`../service-detalis/${langs ?service.slug_ar : service.slug}`}>
                                        <img src={service.photo_url}
                                            alt="serviceimg" width={"100%"} height={"100%"} /></Link>
                                </div>
                                <div className="lower-content">
                                    <h3><i className="fas fa-tooth"></i> <Link
                                            to={`../service-detalis/${langs ?service.slug_ar : service.slug}`}>{langs ? service.name_ar : service.name}</Link>
                                        <i className="fas fa-tooth"></i></h3>
    
                                          <Link to={`../service-detalis/${langs ?service.slug_ar : service.slug}`} className="read-more">
                                              {langs ? "أقرا المزيد" : "Read More"}
                                        <span className="arrow fas fa-angle-double-left"></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                      )
                      })}

            </div>

        </div>
    </div>
);
}

export default ServiceCard;