import React from 'react'

export default function Nopage() {
  return (
    <div id="main">
    <div className="fof">
            <h1>Error 404</h1>
    </div>
</div>
  )
}
