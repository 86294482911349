import React, { useEffect, useState } from 'react';
import {Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { EffectFade } from 'swiper';
import './about.css';
import { useRecoilValue } from 'recoil';
import lang from '../../Atoms/lang';

function AboutDetails() {

const [isAboutSec, setAboutSec] = useState([])
const langs = useRecoilValue(lang)
useEffect(() => {
fetch("https://smilehousedentalcenter.com/api/aboutus")
.then((res) => res.json())
.then((data) => setAboutSec(data.data))
},[isAboutSec])

return (
<>
  <section className="about-slide" lang={`${langs}`}>
    <div className='container'>

      <div className="row">
        <div className="col-lg-6">
          <div className="content-box">
            <div className="sec-title">
              <h2>{ langs ? "عن سمايل هاوس العناية والراحة" : "About US"}</h2>
              <div className="separator style-two"></div>
            </div>
            <div className="text">

              <p>{langs ? isAboutSec.about?.home_about_ar : isAboutSec.about?.home_about} </p>
            </div>

          </div>
        </div>
        <div className="col-lg-6">
          <Swiper modules={[Scrollbar, A11y, EffectFade]} spaceBetween={50} slidesPerView={1}
            autoplay={ true } effect="fade">
            <SwiperSlide>
              <div className='image '><img className="img"
                  src="https://www.smilehousedentalcenter.com/assets/images/ads/16485643541638998016841577263816.jpg"
                  alt='aboutSlide' width={"100%"} height={"100%"} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='image '><img className="img"
                  src="https://www.smilehousedentalcenter.com/assets/images/ads/1648564311163899760821577263816.jpg"
                  alt='aboutSlide' width={"100%"} height={"100%"} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='image '><img className="img"
                  src="https://www.smilehousedentalcenter.com/assets/images/ads/1648564260vvv.jpg" alt='aboutSlide'
                  width={"100%"} height={"100%"} />
              </div>
            </SwiperSlide>






          </Swiper>
        </div>

      </div>
    </div>

  </section>


</>
)

}
export default AboutDetails