import React, { useRef } from 'react'
import { useRecoilValue } from 'recoil'
import lang from '../../Atoms/lang'
import './form.css'
// import { Link } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import { useEffect, useState } from 'react'



const Result =() =>{
    const langs =useRecoilValue(lang)

    return (
        <div className="alert alert-primary fw-bolder" role="alert">
            {langs ? "تم ارسال الرساله, شكرا لك !" : "Message sent, thank you!"}
      </div>
    )
}
export default function CtaForm() {

    const langs =useRecoilValue(lang)
    const [serviceName , setServiceName] = useState([])
    useEffect(() => {
      fetch("https://smilehousedentalcenter.com/api/services")
        .then((res) => res.json())
        .then((data) => setServiceName(data.data))
      }, [])
      const form = useRef();
      const [result,showResult] = useState(false)
      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_cilyfye', 'template_8ik2xwv', form.current, '4I64qfeN03bFuoTV_')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          }
          );
          e.target.reset();
          showResult(true);
        };
return (
<section className="ftco-section p-0" lang={`${langs}`}>
    <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
                <div className="wrapper">
                    <div className="row langss">
                    <div className="col-md-5 d-flex align-items-stretch">
                            <div className="info-wrap bg-primary w-100 p-lg-5 p-4">
                                <h3 className='mb-2 fw-500'>
                                    
                                    {langs ? "مركز سمايل هاوس هو " : "Smile House Clinic"}

                                </h3>
                                <p className='text pl-3'>

                                {langs ? `لاننا نهتم بأدق التفاصيل وابتسامتك تعني لنا الكثير، في عيادات Smile house نقوم باستخدام أحدث المواد التجميلية والتركيبات المصنوعة من الايماكس علي يد نخبة من الأطباء المتخصصين في تجميل الأسنان.
نقوم بتصميم ابتسامتك بدقة واحترافية لكي تتناسب مع ملامح وجهك وتحصل على نتيجة ممتازة ومظهر مثالي يشبه الأسنان الطبيعية.` : ` We care about the smallest details and your smile means a lot to us. In Smile house clinics we use the latest cosmetic materials and Emax fixtures by a group of dentists specializing in cosmetic dentistry.
We design your smile professionally to match the features of your face and get an excellent result and a perfect appearance that resembles natural teeth.`}


                                </p>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex align-items-stretch">
                            <div className="contact-wrap w-100 p-md-5 p-4">
                                <h3 className="mb-4 fw-500">
                                    {langs ? "  احجز موعد" : "Book Now"}
                                </h3>
                                <form id="contactForm"  ref={form} onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" name="name" id="name"
                                                    placeholder={langs ? "الأسم" : "Name"} required />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="email" className="form-control" name="email" id="email"
                                                    placeholder={langs ? "البريد الألكتروني" : "E-mail"} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                            <input type="tel" minLength="11" maxLength="20" className="form-control" name="phone" id="phone"
                                                    placeholder={langs ? "رقم الهاتف" : "phone"} required/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                                <div className="form-group">
                                                <select className="custom-select-box form-control w-100" name='service'>
                                                {serviceName.map((service, index) => {
                                                    return (
                                                      <option key={index}> {langs ? service.name_ar : service.name} </option>  
                                                    )                                                 
                                                })}
                                                </select>
                                              
                                                </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" id="message" cols="30"
                                                    rows="7" placeholder={langs ?"الرساله" : "Message"}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="submit" value={langs ? "ارسال الرساله" : "Send Message"}
                                                    className="btn theme-btn-one" />
                                                <div className="submitting"></div>
                                            </div>
                                        </div>
                                    </div>
                                    {result ? <Result/> : null}

                                </form>
                            </div>
                        </div>
           
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
)
}