import React from 'react';
import logo from './logo.png';
import './footer.css'
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil'
import lang from '../../Atoms/lang';

export default function Footer() {
    const [serviceName , setServiceName] = useState([])
    const langs =useRecoilValue(lang)


    useEffect(() => {
        fetch("https://smilehousedentalcenter.com/api/services")
          .then((res) => res.json())
          .then((data) => setServiceName(data.data))
        }, [])  
        
return (
<>
    <footer>
        <div className="footer_area" lang={`${langs}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-lg-3 col-12">
                        <div className="footer_content_box">
                            <div className="footer_logo">
                                <img src={logo} alt="" />
                            </div>
                            <div className="Subscribe-box">
                                
                                <p>
                            {langs ? `يقدم مركز أسنان سمايل هاوس مجموعة متنوعة من الأطباء المتخصصين فى مجال طب الأسنان و
                                    خدمات التجميل باستخدام أحدث التقنيات الحديثة. خدماتنا التجميلية تشمل الحشوات
                                    التجميلية ، التيجان التجميلية ، جميع تقنيات تبييض الأسنان ، التجميل الكامل للأسنان و
                                    الهوليوود سمايل .` : "Smile House offers a diverse group of specialists covering full range of dental health and advanced cosmetic services with the recent technologies under tight infection control by specialists Our cosmetic services include laminate veneers, cosmetic fillings, cosmetic crowns, all techniques for teeth whitening & gummy smile correction."}
                                    </p>
                                



                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-12">
                        <div className="footer_widget">
                            <h4>
                            {langs ? "التخصصات" : "Services"}
                            </h4>
                            <ul className="widget_menu">
                           {serviceName.map((service, index) => {
                        
                                let title
                                let slug 
                                if (langs) {
                                    
                                  title = service.name_ar
                                  slug = service.slug_ar
                                } else {
                                  title = service.name
                                  slug = service.slug
                                }
                                return (
                                  
                                  <li className='widget' key={index}>
                                  <Link to={`/service-detalis/${slug}`}>{title}</Link>
                                                                                                  
                                                        </li>
                                )
                              })}
                 

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-12">
                        <div className="footer_widget">
                            <h4>
                            {langs ? "مقالات ذات صلة" : "Blogs"}
                            </h4>
                            <ul className="widget_menu">
                                <li>
                                    <Link to="/"><i className="fas fa-arrow-circle-left"></i> 
                            {langs ? "الرئيسية" : "Home"}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/about"><i className="fas fa-arrow-circle-left"></i>
                                    {langs ? "من نحن" : "About Us"}                                    
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blogs"><i className="fas fa-arrow-circle-left"></i> 
                            {langs ? "الاخبار" : "Blogs"}
                                    
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/gallery"><i className="fas fa-arrow-circle-left"></i> 
                            {langs ? "معرض الصور" : "Gallery"}
                                    
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact"><i className="fas fa-arrow-circle-left"></i> 
                            {langs ? "اتصل بنا" : "Contact Us"}
                                
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-12">
                        <div className="footer_widget">
                            <h4>
                            {langs ? "العيادات" : "Address"}
                            </h4>
                            <ul className="widget_menu">

                                <li><i className="fas fa-map-marker-alt"></i><span>                                  
                                <a href="https://goo.gl/maps/2akvCmzMAAn9uYb68">
                              {langs ? "6 ش جلال متفرع من ش اسكندر الاكبر خلف قصر الرئاسة - الكوربة - مصر الجديدة..السبت الي الخميس من ١٠ صباحا حتي ٦ مساء" : "6 M. Galal St. off Eskander el Akbar St. behind Presidential Palace – Korba – Heliopolis.Saturday to Thursday from 10 am to 6 pm"}
                                </a>
                                    </span>
                                </li>
                                <li>
                                    <a href="tel:+201069000434" className="icons_single_contact"><i className="fas fa-phone"></i>+201069000434</a>
                                </li>
                                <li>
                                    <a href="mailto:Smilehousedentalcenter@gmail.com" className="icons_single_contact">
                                    <i className="far fa-envelope"></i>Smilehousedentalcenter@gmail.com</a>
                                </li>




                            </ul>
                            <div className="social-list-2">
                                <ul>

                                    <li> <a href="https://www.facebook.com/smilehousedentalcenter/" className="facebook-bg"><i className="fab fa-facebook-f"></i></a>
                                    </li>

                                    <li> <a href="https://www.instagram.com/smilehouse_dentalcenter/?fbclid=IwAR3JXUZuy5m2lU_ghCgQXpMbasl7gVL4BkwLw_41HXnehuPt0riXV7cSJjM"> <i className="fab fa-instagram"></i></a>
                                    </li>

                                    <li> <a href="https://www.youtube.com/channel/UCNZw4DPM97u0-YlfngP8wIw"> <i className="fab fa-youtube"></i></a>
                                    </li>
                                    <li> <a href="https://www.tiktok.com/@smilehousedentalcenter?_t=8XtpAMz4wwY&_r=1"> <i className="fab fa-tiktok"></i></a>
                                    </li>
                                    <li> <a href="https://wa.me/201069000434"> <i className="fab fa-whatsapp"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright_area">
            <div className="container">
                <div className="row">
                    <div className="col">
                        
                        <div>COPYRIGHT © 2022. All Rights Reserved  
                              <Link to="https://cangrowonline.com/en" className='copy-right'>Cangrowonline</Link>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </footer>

</>
)
}