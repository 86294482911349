import { atom } from "recoil";


let x

if (localStorage.getItem("lang") === "false") {
    x = false
} else {
    x = true
}

const lang = atom({
    key: 'lang',
    default: x
})

export default lang