import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './components/aboutUs/About';
import BlogDetails from "./components/blogs/BlogDetails";
import Blogs from "./components/blogs/Blogs";
import Form from "./components/callToAction/Form";
import CtaForm from "./components/callToAction/CtaForm";
import Footer from './components/footer/Footer';
import HomeGallery from './components/gallery/HomeGallery';
import GalleryFilter from "./components/galleryFilter/GalleryFilter";
import Swiper from './components/home-swiper/Swiper';
import Navbar from './components/navbar/Navbar';
import ServiceDetails from "./components/services-details/ServiceDetails";
import ServiceCard from './components/servicesCard/ServiceCard';
import Videos from './components/videos/Videos';
import CallsButtons from "./components/CallsButtons";
import SocialIcon from "./components/SocialIcon";
import { RecoilRoot } from "recoil";
import Nopage from "./components/Nopage";
import ScrollToTop from "./components/ScrollTop";
import AboutDetails from "./components/aboutUs/AboutDetailes";



function App() {
  
  return (
    <BrowserRouter>
      <RecoilRoot>
        <Navbar />
        <ScrollToTop/>
        <Routes>
            <Route path="/" element={<><Swiper/> <About /> <CtaForm/>  <HomeGallery /><CallsButtons/>  <ServiceCard /><CtaForm/>  <Videos /> <CallsButtons/><Blogs/> <Form/> </>}>
            </Route>
            <Route path="/about" element={<><AboutDetails /> <CallsButtons/></>}>
            </Route>
            <Route path="/service-detalis/:slug" element={<> <ServiceDetails/><CallsButtons/> <CtaForm/></>}>
            </Route>
            <Route path="/blogs" element={<> <Blogs/><CallsButtons/> <CtaForm/></>}>
              </Route>
            <Route path="/gallery" element={<> <GalleryFilter/> <CallsButtons/> </>}>
            </Route>
            <Route path="/videos" element={<> <Videos/> <CallsButtons/></>}>
            </Route>
            <Route path="/blog-detalis/:slug" element={<> <BlogDetails/> <CallsButtons/> <CtaForm/></>}>
            </Route>
            <Route path="/contact" element={<> <Form/><CallsButtons/> </>}>
            </Route>
            <Route path="/appointment" element={<> <CtaForm/><CallsButtons/> </>}>
            </Route>
            <Route path="*" element={<Nopage />} />
        </Routes>
        <SocialIcon/>
        <Footer />
      </RecoilRoot>
   </BrowserRouter>
  );
}

export default App;
