import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { useRecoilValue } from 'recoil';
import lang from '../../Atoms/lang';

import './filter.css'
const GalleryFilter = () => {
    const onInit = () => {
        // console.log('lightGallery has been initialized');
    };
    const langs = useRecoilValue(lang)

    const [images ,setImages] = useState([])

     useEffect(() => {
        fetch("https://smilehousedentalcenter.com/api/gallery2")
            .then((res) => res.json())
            .then((data) => setImages(data.data))
    },[])


return (
<section className='gallery_area' lang={`${langs}`}>
<div className="container">
  <div className="row">
    <div className="col-md-12">
			<div className="tabbable-panel">
				<div className="tabbable-line">
                    <div className="tab-pane fade active show" id="pills-all" role="tabpanel" aria-labelledby="pills-all-tab" tabIndex="0">
                    <section className='mfa-gallery p-0'>
              <LightGallery
                    onInit={onInit}
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                >
          
                       {images.map((image) => {                            
                        return (
                                <a href={`${image?.photo_url}`}>
                                <img alt="Lightgallery" src={`${image?.photo_url}`} width={"100%"} height={"100%"} />
                                <div className="gallery_hover_content">
                                <i className="fas fa-search"></i>
                                </div>
                            </a>
                            )
                        })}

            </LightGallery>
                </section>
                    </div>
				</div>
    </div>
  </div>
</div>
</div>
</section>
);
}

export default GalleryFilter;