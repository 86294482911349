import React from 'react'

export default function SocialIcon() {
  return (
    <div className='Social-icon'>
        <a href="tel:+201069000434" className="btn-phone-pulse">
            <i className="fas fa-phone"></i>
        </a>
        <a href="https://www.facebook.com/smilehousedentalcenter/" className="btn-facebook-pulse">
            <i className="fab fa-facebook-f"></i>
    
        </a>
        <a href="https://www.instagram.com/smilehouse_dentalcenter/?fbclid=IwAR3JXUZuy5m2lU_ghCgQXpMbasl7gVL4BkwLw_41HXnehuPt0riXV7cSJjM" className="btn-instagram-pulse2">
            <i className="fab fa-instagram"></i>
    
        </a>
        <a href="https://www.youtube.com/channel/UCNZw4DPM97u0-YlfngP8wIw" className="btn-youtube-pulse">
            <i className="fab fa-youtube"></i>
        </a>
        <a href="https://www.tiktok.com/@smilehousedentalcenter?_t=8XtpAMz4wwY&_r=1" className="btn-tiktok-pulse">
            <i className="fab fa-tiktok"></i>
        </a>
        <a href="https://wa.me/201069000434" className="btn-whatsapp-pulse">
            <i className="fab fa-whatsapp"></i>
        </a>
    </div>
  )
}
