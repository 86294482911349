import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { EffectFade } from 'swiper';
import './swipers.css'
import { useRecoilValue } from 'recoil';
import lang from '../../Atoms/lang';
import {Link} from 'react-router-dom';


function Swipers() {
  const langs = useRecoilValue(lang)
  
  const [sliderImg, setSliderImg] = useState([])
  
  useEffect(() => {
    fetch("https://smilehousedentalcenter.com/api/sliders")
      .then((res) => res.json())
      .then((data) => setSliderImg(data.data))
  },[])


  return (
        
        <Swiper 
     
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={
       true
    }
      navigation
      effect="fade"
      pagination={{ clickable: true }}
      
    >
     {sliderImg.map((slider , index) => {
        return (
          <SwiperSlide key={index}>
            <div className='image '>
              <img className="img" src={slider.photo_url} alt="sliderImage" width={"100%"} height={"100%"} />
            </div>
            <div className="slider-text" lang={`${langs}`}>
                    <div className="text-buttons">
                        <div className="text">
                          <p>
                          { langs ? slider.subtitle_text_ar : slider.subtitle_text }
                          </p>
                          <p>
                          { langs ? slider.title_text_ar : slider.title_text }
                          </p>
                          <div className="buttons">
                             <Link className="btn theme-btn-one" to="/about">
                          { langs ? slider.btn_text_ar : slider.btn_text }
                         </Link>
                          </div>
                         
                        </div>

                    </div>
                </div>
      </SwiperSlide>
        )
      })}
      
    </Swiper>
  )
}
export default Swipers;