import logo from './logo.png';
import './navbar.css';
import {Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import lang from '../../Atoms/lang';


function BasicExample () {
  const [serviceName , setServiceName] = useState([])
  const [langs , setLangs] = useRecoilState(lang)


  useEffect(() => {
    fetch("https://smilehousedentalcenter.com/api/services")
      .then((res) => res.json())
      .then((data) => setServiceName(data.data))
    }, [])  
  return (
    <>
      {
        langs ?
                <header className='elementskit-header main-header'>
      <nav className="navbar navbar-expand-lg bg-light elementskit-navbar">
        <div className="container">
          <Link className="navbar-brand" to="/"><img src={logo} alt="logo" srcSet="" /></Link>
          <button className=" elementskit-menu-toggler xs-bold-menu justify-content-center align-items-center">

            <div className="xs-gradient-group">
              <span></span>
              <span></span>
              <span></span>
            </div>

          </button>
          <div className="elementskit-menu-container elementskit-menu-offcanvas-elements">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0 elementskit-navbar-nav nav-alignment-dynamic">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">الرئيسية</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">من نحن</Link>
              </li>

              <li className="nav-item elementskit-dropdown-has"><i className="fas fa-chevron-down"></i>
                <Link className='nav-link' to="#">
                التخصصات

                </Link>
                <ul className="elementskit-dropdown elementskit-submenu-panel">
                    {serviceName.map((service, index) => {
                      let title 
                      let slug 
                      if (langs) {
                        title = service.name_ar
                        slug = service.slug_ar
                      } else {
                        title = service.name
                        slug = service.slug
                      }
                      return (
                        
                        <li key={index}>
                        <Link to={`/service-detalis/${slug}`}>{title}</Link>
																						
											  </li>
                      )
                      })}

                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gallery">معرض الصور</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/videos">معرض الفيديوهات</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blogs">اخر الأخبار</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">اتصل بنا</Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/ar" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                <img src="https://www.smilehousedentalcenter.com/assets/images/ar.jpg" className='lang-img' alt="" />
                </Link>
                  <ul className="dropdown-menu">
                    <button onClick={() => {
                      localStorage.setItem("lang" , true)
                      setLangs(true)
                      
                    }}>
                  <img src="https://www.smilehousedentalcenter.com/assets/images/ar.jpg" className='lang-img' alt="" />
                    </button>
                    <button onClick={() => {
                      localStorage.setItem("lang" , false)
                      setLangs(false)
                    }}>

                  <img src="https://www.smilehousedentalcenter.com/assets/images/en.jpg" className='lang-img' alt="" />
                    </button>
                </ul>
              </li>
                <li className="nav-item">

                    <Link className="btn theme-btn-one" to="/appointment">
                    احجز الآن
                    </Link>
                  </li>
                  
            </ul>
            <div className="elementskit-nav-identity-panel">

              <span className="elementskit-menu-close elementskit-menu-toggler d-flex justify-content-center align-items-center" type="button"><i
                  className="fas fa-times"></i></span>
            </div>
          </div>
          <div className="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler">
          </div>
        </div>
      </nav>
          </header>
          :
          <header className='elementskit-header main-header' lan={"en"}>
      <nav className="navbar navbar-expand-lg bg-light elementskit-navbar">
        <div className="container">
          <Link className="navbar-brand" to="/"><img src={logo} alt="logo" srcSet="" /></Link>
          <span className=" elementskit-menu-toggler xs-bold-menu justify-content-center align-items-center">

            <div className="xs-gradient-group">
              <span></span>
              <span></span>
              <span></span>
            </div>

          </span>
          <div className="elementskit-menu-container elementskit-menu-offcanvas-elements">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0 elementskit-navbar-nav nav-alignment-dynamic">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">About Us</Link>
              </li>

              <li className="nav-item elementskit-dropdown-has"><i className="fas fa-chevron-down"></i>
                <Link className='nav-link' to="#">
                Services

                </Link>
                <ul className="elementskit-dropdown elementskit-submenu-panel">
                    {serviceName.map((service, index) => {
                      let title 
                      let slug 
                      if (langs) {
                        title = service.name_ar
                        slug = service.slug_ar
                      } else {
                        title = service.name
                        slug = service.slug
                      }
                      return (
                        
                        <li key={index}>
                        <Link to={`/service-detalis/${slug}`}>{title}</Link>
																						
											  </li>
                      )
                      })}

                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gallery">Gallery</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/videos">Videos</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blogs">Blog</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact Us</Link>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/ar" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                <img src="https://www.smilehousedentalcenter.com/assets/images/en.jpg" className='lang-img' alt="" />
                </Link>
                  <ul className="dropdown-menu">
                    <button onClick={() => {
                      localStorage.setItem("lang" , true)

                      setLangs(true)
                    }}>
                  <img src="https://www.smilehousedentalcenter.com/assets/images/ar.jpg" className='lang-img' alt="" />
                    </button>
                    <button onClick={() => {
                      localStorage.setItem("lang" , false)

                      setLangs(false)

                    }}>

                  <img src="https://www.smilehousedentalcenter.com/assets/images/en.jpg" className='lang-img' alt="" />
                    </button>
                </ul>
              </li>
                <li className="nav-item">

                    <Link className="btn theme-btn-one" to="/appointment">
                    Book Now 
                    </Link>
                  </li>
            </ul>
            <div className="elementskit-nav-identity-panel">

              <span className="elementskit-menu-close elementskit-menu-toggler d-flex justify-content-center align-items-center" type="button"><i
                  className="fas fa-times"></i></span>
            </div>
          </div>
          <div className="elementskit-menu-overlay elementskit-menu-offcanvas-elements elementskit-menu-toggler">
          </div>
        </div>
      </nav>
                </header>
      }
    
  </>
  );
  }
  export default BasicExample;