import React, { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import './gallery.css';
import { useRecoilValue } from 'recoil';
import lang from '../../Atoms/lang';


function HomeGallery() {

const langs = useRecoilValue(lang)

const onInit = () => {
// console.log('lightGallery has been initialized');
};

const [images ,setImages] = useState([])

useEffect(() => {
fetch("https://smilehousedentalcenter.com/api/home-gallery")
.then((res) => res.json())
.then((data) => setImages(data.data))
},[])


return (
<section className='mfa-gallery container'>
    <div className="sec-title centered">
        <h2>{langs ? "معرض الصور" : "Gallery" }</h2>
        <div className="separator"></div>
    </div>
   
    <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
        {images.map((image , index) => {
        if (index <= 7) { return ( <a href={`${image?.photo_url}`} key={index}>
            <img alt="Lightgallery" src={`${image?.photo_url}`} width={"100%"} height={"100%"} />
            <div className="gallery_hover_content">
                <i className="fas fa-search"></i>
            </div>
            </a>
            )
            }
            })}
    </LightGallery>
</section>
)
}

export default HomeGallery;