import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import $ from 'jquery';
import reportWebVitals from './reportWebVitals'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <App />
    
  </React.StrictMode>
);
$(document).ready(function ($) {

  function elementskit_event_manager(_event, _selector, _fn){
      $(document).on(_event, _selector, _fn);
  }

  elementskit_event_manager('click', '.elementskit-dropdown-has > a', function (e) {
  e.preventDefault();

  // var menu = $(this).parents('.elementskit-navbar-nav');
  var li = $(this).parent();
  var dropdown = li.find('.elementskit-dropdown');

  dropdown.find('.elementskit-dropdown-open').removeClass('elementskit-dropdown-open');

  $(window).on('resize', function() {
    if ($(window).width() > 991) {
      $(dropdown).removeClass('elementskit-dropdown-open');
    }
  })
  if(dropdown.hasClass('elementskit-dropdown-open')){
    dropdown.removeClass('elementskit-dropdown-open');
  }else{
    dropdown.addClass('elementskit-dropdown-open');
  }

});

elementskit_event_manager('click', '.elementskit-menu-toggler', function (e) {
  e.preventDefault();
  var parent_conatiner = $(this).parents('.elementskit-menu-container').parent();
  if(parent_conatiner.length < 1){
    parent_conatiner = $(this).parent();
  }
  var off_canvas_class = parent_conatiner.find('.elementskit-menu-offcanvas-elements');

  $(window).on('resize', function() {
    if ($(window).width() > 991) {
      off_canvas_class.removeClass('active');
    }
  })
  if(off_canvas_class.hasClass('active')){
    off_canvas_class.removeClass('active');
  }else{
    off_canvas_class.addClass('active');
  }

});


}); 
$(window).on('scroll', function() {    
  var scroll = $(window).scrollTop();

  if (scroll >= 50) {
    $(".main-header .header-lower").addClass("sticky-header");
  } else {
    $(".main-header .header-lower").removeClass("sticky-header");
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
